import './App.css';

import React from 'react';
import logo from '../../assets/images/logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          alt="logo"
          className="App-logo"
          height="98"
          src={logo}
          width="300"
        />
        <p className="App-soon">
          Bientôt disponible.
          <br />
          <a href="//www.facebook.com/miamappio/">Facebook</a>
          {' • '}
          <a href="//www.instagram.com/miamappio/">Instagram</a>
          {' • '}
          <a href="//twitter.com/miamappio">Twitter</a>
        </p>
      </header>
    </div>
  );
}

export default App;
